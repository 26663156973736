<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title style="color: white;">Company Information Report</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card style="padding: 10px">
      <div>
        <v-row style="padding-left: 10px;padding-right: 10px;">
          <v-col sm="4">
            <v-select label="Academic Year" :items="fetch_ay_list" v-model="fetch_filter.academicYear"
              :rules="[(v) => !!v || 'required']" item-text="aylist" item-value="id" outlined></v-select>
          </v-col>
          <v-col sm="4">
            <v-select label="Company" :items="fetch_company_list" v-model="fetch_filter.companyList"
              :rules="[(v) => !!v || 'required']" item-text="name" item-value="id" outlined></v-select>
          </v-col>
          <v-col sm="4">
            <v-select :items="placement_type_list" v-model="fetch_filter.placementList"
              :rules="[(v) => !!v || 'required']" label="Placement Type" item-text="name" item-value="id"
              outlined></v-select>
          </v-col>
        </v-row>
        <v-row style="padding-left: 10px;padding-right: 10px;">
          <v-col sm="4">
            <v-select :items="company_offering_status" v-model="fetch_filter.status" label="Offering Status"
              item-text="name" item-value="id" outlined></v-select>
          </v-col>
          <v-col sm="4">
            <v-select :items="company_type_list" v-model="fetch_filter.companyLst" :rules="[(v) => !!v || 'required']"
              label="Company Type" item-text="name" item-value="id" outlined></v-select></v-col>
          <v-col sm="4">
            <v-select :items="company_level_list" v-model="fetch_filter.companyLevel" :rules="[(v) => !!v || 'required']"
              label="Company Level" item-text="name" item-value="id" outlined></v-select></v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <center>
              <v-btn color="primary" dark class="mb-2" :loading="loading" :loader="loader3" @click="fetch()" dense>
                Fetch</v-btn>
            </center>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <div>
      <template>
        <v-toolbar flat class="tc-title1">

          <div justify="center" style="width: 50%; padding-left: 10%;">
            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <span style="width: 40px"></span>
          <download-excel style="padding-right: 10%;" v-if="offer_company_list.length > 0" class="mr-2 pb-3 pt-2"
            :data="offer_company_list" :fields="table_fields" worksheet="Compnay_Scheduling_Info"
            name="Compnay_Scheduling_Info.xls">
            <v-btn color="success" style="background-color: white;" outlined rounded><v-icon dark>mdi-file-excel</v-icon>
              Excel</v-btn>
          </download-excel>
        </v-toolbar>

      </template>
      <template>
        <v-data-table :headers="headers" :items="offer_company_list" :expanded.sync="expanded" :expanded="expanded"
          show-expand single-expand item-key="srno" :search="search">
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" sm="3" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Name :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.company }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black; ">
                  <span style="color: black; font-size: 12px; font-weight: normal">Academic Year :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.ay }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black; ">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company code :
                  </span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.company_code }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black; ">
                  <span style="color: black; font-size: 12px; font-weight: normal">Semester :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.semester }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Offering : </span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.placementtype }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Internship Duration :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.internshipduration }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company type :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.companytype }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Level :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.companylevel }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Domain :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">
                    <span v-for="(i, index) in item.industry" :key=index>{{ i }} <br></span>
                  </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Min Package/Stipend :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.minPackage }} </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Max Package/Stipend :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.maxPackage }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Job Description :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.job_description }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Job Location :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.locations }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Placement Process Location :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.placement_process_locations
                  }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Person Name :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.contact_person_name }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Person Email :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.contact_email }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Person Contact :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.contact_phone }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Allowed Institutes :</span>
                  <br />
                  <span v-if="item.organization.length == 1" style="color: blue; font-size: 14px; font-weight: bold">{{
                    item.organization[0] }}.</span>
                  <span v-else style="color: blue; font-size: 14px; font-weight: bold">
                    <div v-for="(org, index) in item.organization" :key="index">
                      <span v-if="item.organization.length == index + 1"> {{ org }}.</span>
                      <span v-else> {{ org }}, </span>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Required Skills :</span>
                  <br />
                  <span v-if="item.skill.length == 1" style="color: blue; font-size: 14px; font-weight: bold">{{
                    item.skill[0] }}.</span>
                  <span v-else style="color: blue; font-size: 14px; font-weight: bold">
                    <div v-for="(skill, index) in item.skill" :key="index">
                      <span v-if="item.skill.length == index + 1"> {{ skill }}.</span>
                      <span v-else> {{ skill }}, </span>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Allowed Branches :</span>
                  <br />
                  <span v-if="item.tpoprogram.length == 1" style="color: blue; font-size: 14px; font-weight: bold"> {{
                    item.tpoprogram[0] }}.</span>
                  <span v-else style="color: blue; font-size: 14px; font-weight: bold">
                    <div v-for="(prog, index) in item.tpoprogram" :key="index">
                      <span v-if="item.tpoprogram.length == index + 1"> {{ prog }}.</span>
                      <span v-else> {{ prog }}, </span>
                    </div>
                  </span>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Collapse from "vue-collapse";
import readXlsxFile from "read-excel-file";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  components: { Collapse },

  data: () => ({
    search: '',
    expanded: [],
    headers: [
      { text: '', value: 'data-table-expand' },
      {
        text: 'Sr no',
        align: 'left',
        value: 'srno',
        width: "6.3%"
      },
      { text: 'Company', value: 'company' },
      { text: 'Offering Code', value: 'company_code' },
      { text: 'Registration start Date', value: 'regStartdate', width: "12%" },
      { text: 'Registration end Date', value: 'regEnddate', width: "12%" },
      { text: 'Placement Type', value: 'placementtype' },
      { text: 'Company Type', value: 'companytype' },
      { text: 'Company Level', value: 'companylevel' },
      { text: 'Allowed Institues', value: 'organization', width: "10.2%" },
      { text: 'Criteria', value: 'criteria' }

    ],

    table_fields: {
      "Company": "company",
      "Offering Code": "company_code",
      "Academic Year": "ay",
      "Semester": "semester",
      "Offering": "placementtype",
      "Internship Duration": "internshipduration",
      "Company Type": "companytype",
      "Company Level": "companylevel",
      "Department": "department",
      "Domain": "industry",
      "Job Location": "locations",
      "Job Description": "job_description",
      "Min Package In Lakhs": "minPackage",
      "Max Package In Lakhs": "maxPackage",
      "Registration start Date": "regStartdate",
      "Registration start Time": "regStarttime",
      "Registration end Date": "regEnddate",
      "Registration end Time": "regEndtime",
      "From Schedule Date": "from_schedule_date",
      "To Schedule Date": "to_schedule_date",
      "Required Skills": "skill",
      "Allowed Branches": "tpoprogram",
      "Criteria": "criteria",
      "Allowed Institutes": "organization",
      "Contact Person Name": "contact_person_name",
      "Contact Person Email": "contact_email",
      "Contact Person Phone": "contact_phone",
      "Placement Process Locations": "placement_process_locations"
    },

    overlay: false,
    loader3: false,
    loading: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    offer_list_size: "",
    fetch_ay_list: [],

    fetch_company_list: [],

    placement_type_list: [],

    company_offering_status: [],

    company_type_list: [],

    company_level_list: [],

    offer_company_list: [],


    fetch_filter: {
      academicYear: "",
      companyList: "",
      placementList: "",
      status: "",
      companyLst: "",
      companyLevel: "",
    }


  }),



  mounted() {
    this.onLoad();
  },



  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },


    onLoad() {
      this.loader = true;
      axios
        .post("/TPOCompanyScheduling/offerCompanySchedulingFetch")
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader = false;
            this.fetch_ay_list = res.data.fetch_ay_list;
            this.fetch_ay_list.push({ aylist: "ALL", id: "ALL" });
            this.fetch_filter.academicYear = "ALL";
            this.fetch_company_list = res.data.fetch_company_list;
            this.fetch_company_list.push({ name: "ALL", id: "ALL" });
            this.fetch_filter.companyList = "ALL";
            this.placement_type_list = res.data.placement_type_list;
            this.placement_type_list.push({ name: "ALL", id: "ALL" });
            this.fetch_filter.placementList = "ALL";
            this.company_offering_status = res.data.company_offering_status;
            this.company_offering_status.push({ name: "ALL", id: "ALL" });
            this.fetch_filter.status = "ALL";
            this.company_type_list = res.data.company_type_list;
            this.company_type_list.push({ name: "ALL", id: "ALL" });
            this.fetch_filter.companyLst = "ALL";
            this.company_level_list = res.data.company_level_list;
            this.company_level_list.push({ name: "Institute Level", id: "Institute Level" });
            this.fetch_filter.companyLevel = "Institute Level";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {

        });

    },



    fetch() {
      this.loader3 = true;
      this.loading = true;
      var params = {
        ay: this.fetch_filter.academicYear,
        company: this.fetch_filter.companyList,
        placement: this.fetch_filter.placementList,
        status: this.fetch_filter.status,
        companytype: this.fetch_filter.companyLst,
        companylevel: this.fetch_filter.companyLevel,
        offset: this.fetch_filter.offset,
      };
      axios
        .post("/TPOCompanyScheduling/offerCompanySchedulingFetchDetails", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader3 = false;
            this.loading = false;
            this.offer_company_list = res.data.offer_company_list;
            this.offer_list_size = res.data.offer_list_size;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {

        });
    },

  },

}
</script>

<style>
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #2d8ea6);
  color: #fff;
  border-radius: 3px;
}

.tc-title1 {
  background-image: linear-gradient(-90deg, #fff, #2d8ea6);
  color: #fff;
  border-radius: 3px;
}
</style>